import { useState, useEffect } from 'react'

import { type IBalance } from '../../../@types/IBalance'
import { type IOverview } from '../../../@types/IOverview'
import { useChanges } from '../../../hooks/ChangesContextProvider'
import { useWallet } from '../../../hooks/WalletContextProvider'
import { api } from '../../../services/api'
import { parseMoney } from '../../../services/utils/parseMoney'
import { OverviewTypeSelect } from './OverviewTypeSelect'
import { Container, Header, Row, Box, BalanceBox } from './styles'

function getMoneyClasses(value: number | undefined) {
  const classes = ['money']
  if (value && value < 0) {
    classes.push('negative')
  }
  return classes.join(' ')
}

export const OverviewPanel: React.FC = () => {
  const [overviewType, setOverviewType] = useState('monthly')
  const [balance, setBalance] = useState<IBalance>()
  const [overview, setOverview] = useState<IOverview>()
  const { currentWallet } = useWallet()
  const { changes } = useChanges()

  useEffect(() => {
    async function getBalance() {
      if (!currentWallet?.id) return
      const [{ data: overviewData }, { data: balanceData }] = await Promise.all(
        [
          api.get<IOverview>(`/wallet/${currentWallet.id}/balance/overview`),
          api.get<IBalance>(`/wallet/${currentWallet.id}/balance`, {
            params: {
              type: overviewType || 'monthly',
            },
          }),
        ],
      )

      balanceData.incomeFormatted = parseMoney(balanceData.income)
      balanceData.outcomeFormatted = parseMoney(balanceData.outcome)
      overviewData.today.balanceFormatted = parseMoney(
        overviewData.today.balance,
      )
      overviewData.week.balanceFormatted = parseMoney(overviewData.week.balance)
      overviewData.month.balanceFormatted = parseMoney(
        overviewData.month.balance,
      )
      overviewData.total.balanceFormatted = parseMoney(
        overviewData.total.balance,
      )

      setBalance(balanceData)
      setOverview(overviewData)
    }

    getBalance().catch(console.error)
  }, [overviewType, changes.transactions, currentWallet])

  return (
    <Container>
      <Header>
        <h3>Visão Geral</h3>

        <OverviewTypeSelect value={overviewType} setValue={setOverviewType} />
      </Header>
      <Row>
        <Box type="income">
          <h4>Entradas</h4>
          <div className="row">
            <div className="money-item">
              <p className="money">{balance?.incomeFormatted}</p>
              <p className="info">Este mês</p>
            </div>
          </div>
        </Box>
        <Box type="outcome">
          <h4>Saídas</h4>
          <div className="row">
            <div className="money-item">
              <p className="money">{balance?.outcomeFormatted}</p>
              <p className="info">Este mês</p>
            </div>
          </div>
        </Box>
      </Row>
      <BalanceBox>
        <h4>Saldo</h4>
        <div className="money-item small">
          <p className={getMoneyClasses(overview?.today.balance)}>
            {overview?.today.balanceFormatted}
          </p>
          <p className="info">Hoje</p>
        </div>
        <div className="money-item small">
          <p className={getMoneyClasses(overview?.week.balance)}>
            {overview?.week.balanceFormatted}
          </p>
          <p className="info">Esta semana</p>
        </div>
        <div className="money-item medium">
          <p className={getMoneyClasses(overview?.month.balance)}>
            {overview?.month.balanceFormatted}
          </p>
          <p className="info">Este mês</p>
        </div>
        <div className="money-item large">
          <p className={getMoneyClasses(overview?.total.balance)}>
            {overview?.total.balanceFormatted}
          </p>
          <p className="info">Total</p>
        </div>
      </BalanceBox>
    </Container>
  )
}
